import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * For pre-authentication pages that you should not be able to go to after authentication
 * @param {*} auth from the useAuth hook - typically extracted at parent component level
 * @returns null
 */
export function useRedirectAuthenticatedUsers({ auth }) {
  const navigate = useNavigate();

  // get current location
  const location = useLocation();
  console.log("location", location);

  useEffect(() => {
    // if current location is auth/register, redirect to onboarding and no-op dashboard redirect
    const isRegistering = location.pathname === "/auth/register";

    if (auth.isUserAuthenticated && !isRegistering) {
      navigate("/dashboard");
    }

    if (auth.isUserAuthenticated && isRegistering) {
      navigate("/onboarding");
    }
  }, [auth.isUserAuthenticated]);

  return null;
}
