const veterinaryGroups = [
  {
    label: "Alliance Animal Health",
    value: "alliance_animal_health",
    email: "@allianceanimal.vet",
  },
  {
    label: "American Veterinary Group",
    value: "american_veterinary_group",
    email: "@americanveterinarygroup.com",
  },
  {
    label: "Amerivet Veterinary Partners",
    value: "amerivet_veterinary_partners",
    email: "@amerivet.com",
  },
  { label: "AZ Pet Vet", value: "az_pet_vet", email: "" },
  {
    label: "BluePearl Veterinary Partners",
    value: "bluepearl_veterinary_partners",
    email: "@bluepearlvet.com",
  },
  {
    label: "BlueRiver Pet Care",
    value: "blueriver_pet_care",
    email: "@blueriverpetcare.com",
  },
  { label: "Bond Vet", value: "bond_vet", email: "@bondvet.com" },
  {
    label: "Cara Veterinary",
    value: "cara_veterinary",
    email: "@caraveterinary.com",
  },
  { label: "CareVet", value: "carevet", email: "@carevethealth.com" },
  { label: "CityVet", value: "cityvet", email: "@cityvet.com" },
  {
    label: "Community Veterinary Partners",
    value: "community_veterinary_partners",
    email: "",
  },
  { label: "Curo Pet Care", value: "curo_pet_care", email: "" },
  { label: "Destination Pet", value: "destination_pet", email: "" },
  { label: "Encore Vet Group", value: "encore_vet_group", email: "" },
  {
    label: "EverVet Partners",
    value: "evervet_partners",
    email: "@evervetpartners.com",
  },
  {
    label: "Galaxy Vets",
    value: "galaxy_vets",
    email: "@galaxyvets.com",
  },
  { label: "GoodVets", value: "goodvets", email: "" },
  {
    label: "Heart + Paw",
    value: "heart_paw",
    email: "@heartandpaw.com",
  },
  {
    label: "Heartland Veterinary Partners",
    value: "heartland_veterinary_partners",
    email: "@heartlandvetpartners.com",
  },
  {
    label: "Innovetive PetCare",
    value: "innovetive_petcare",
    email: "@innovetivepetcare.com",
  },
  {
    label: "Inspire Veterinary Partners",
    value: "inspire_veterinary_partners",
    email: "@inspirevet.com",
  },
  {
    label: "Lakefield Veterinary Group",
    value: "lakefield_veterinary_group",
    email: "@lakefieldvet.com",
  },
  { label: "MedVet", value: "medvet", email: "@medvet.com" },
  {
    label: "Mission Veterinary Partners (MVP)",
    value: "mvp",
    email: "@mvetpartners.com",
  },
  {
    label: "Modern Animal",
    value: "modern_animal",
    email: "@modernanimal.com",
  },
  {
    label: "My Pet’s Vet Group (MPVG)",
    value: "my_pets_vet_group",
    email: "",
  },
  {
    label: "National Veterinary Associates (NVA)",
    value: "nva",
    email: "@nva.com",
  },
  {
    label: "O’Brien Veterinary Group",
    value: "obrien_veterinary_group",
    email: "",
  },
  { label: "Pet Paradise", value: "pet_paradise", email: "" },
  {
    label: "PetVet Care Centers",
    value: "petvet_care_centers",
    email: "",
  },
  {
    label: "Rarebreed Veterinary Partners",
    value: "rarebreed_veterinary_partners",
    email: "",
  },
  {
    label: "Southern Veterinary Partners (SVP)",
    value: "svp",
    email: "@svp.vet",
  },
  { label: "The Vets Pets", value: "the_vets_pets", email: "" },
  {
    label: "Thrive Pet Healthcare",
    value: "thrive_pet_healthcare",
    email: "@thrivepet.com",
  },
  {
    label: "United Veterinary Care",
    value: "united_veterinary_care",
    email: "",
  },
  {
    label: "Valley Veterinary Care",
    value: "valley_veterinary_care",
    email: "@valleyvet.vet",
  },
  { label: "VCA", value: "vca", email: "@vca.com" },
  {
    label: "Veritas Veterinary Partners",
    value: "veritas_veterinary_partners",
    email: "",
  },
  { label: "VetCor", value: "vetcor", email: "@vetcor.com" },
  {
    label: "Veterinary Emergency Group (VEG)",
    value: "veg",
    email: "@veg.vet",
  },
  {
    label: "Veterinary Practice Partners",
    value: "veterinary_practice_partners",
    email: "",
  },
  {
    label: "Veterinary United",
    value: "veterinary_united",
    email: "",
  },
  { label: "VetEvolve", value: "vetevolve", email: "@vetevolve.com" },
  { label: "VetnCare", value: "vetncare", email: "@vetncare.com" },
  {
    label: "VetStrategy",
    value: "vetstrategy",
    email: "@vetstrategy.com",
  },
  { label: "VIP Pet Care", value: "vip_pet_care", email: "" },
  {
    label: "WellHaven PetHealth",
    value: "wellhaven_pethealth",
    email: "@wellhaven.com",
  },
  {
    label: "Western Veterinary Partners",
    value: "western_veterinary_partners",
    email: "@westernvetpartners.com",
  },
];

export default veterinaryGroups;
