import { gql, useQuery } from "@apollo/client";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";

const GET_ALLOWED_ROLES = gql`
  query getAllowedRoles {
    allAllowedRoles {
      value
      name
    }
  }
`;

const GET_ROLE = gql`
  query getRole {
    role {
      id
      name
    }
  }
`;

const RoleContext = createContext();

const RoleProvider = ({ children }) => {
  const [allowedRoles, setAllowedRoles] = useState({});
  const [role, setRole] = useState({});
  const { data } = useQuery(GET_ALLOWED_ROLES);
  const { data: roleData } = useQuery(GET_ROLE);

  useEffect(() => {
    if (data) {
      setAllowedRoles(
        Object.fromEntries(
          data.allAllowedRoles.map((obj) => [obj.name, obj.value]),
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    if (roleData) {
      setRole(roleData?.role);
    }
  }, [roleData]);

  return (
    <RoleContext.Provider value={{ allowedRoles, role }}>
      {children}
    </RoleContext.Provider>
  );
};

/**
 * Custom React hook that provides access to:
 * - allowedRoles: Obj (lists the RoleEnum from the BE)
 * - role: Obj|null (returns the current user's role, resolves the full row from the BE)
 *
 * **allowedRoles per Teams V2**
 * The following keys are assessible via the 'allowedRoles' object:
 * - **DVM**: Doctor of Veterinary Medicine
 * - **PRACTICE_MANAGER**: Oversees practice operations and may assign tasks
 * - **LOCUM**: A temporary veterinarian (does not belong to a clinic)
 * - **TECHNICIAN**: Veterinary technician assisting with patient care
 * - **OTHER**: Any other type of role not listed above
 */
const useRole = () => {
  return useContext(RoleContext);
};

export { RoleProvider, useRole };
