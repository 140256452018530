import { gql, useQuery } from "@apollo/client";
import { startOfDay, endOfDay } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";

import DateRangeSelection from "./DateRangeSelection";
import InboxWindow from "./InboxWindow";
import { inboxStepsBasic } from "../../assets/data/JoyrideTourSteps";
import { TAB_ARRAY } from "../../constants";
import { NOTE_FIELDS } from "../../graphql/fragments/note";
import { useWatchNoteUpdates } from "../../hooks";
import { useNoteMultiselectContext } from "../../hooks";
import JoyrideTour from "../common/modals/JoyrideTour";
import { MultiSelectActionContainer } from "../common/multiselect/MultiSelectActionContainer";
import Tabs from "../common/tabs/Tabs";
import PageTitle from "../layout/PageTitle";

const GET_INBOX_NOTES = gql`
  ${NOTE_FIELDS}
  query getInboxNotes(
    $filter: String!
    $limit: Int
    $offset: Int
    $sortAscending: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    inboxNotes(
      filter: $filter
      limit: $limit
      offset: $offset
      sortAscending: $sortAscending
      startDate: $startDate
      endDate: $endDate
    ) {
      totalNotes
      notes {
        patient {
          uuid
          name
          lastNameOfOwner
          sex
          species
        }
        ...NoteFields
      }
    }
  }
`;

const TAB_COUNT_QUERY = gql`
  query getInboxTabCount($startDate: DateTime, $endDate: DateTime) {
    inboxTabCount(startDate: $startDate, endDate: $endDate) {
      numSavedDrafts
      numNowProcessing
      numForReview
      numForExport
      numExportedItems
    }
  }
`;

const defaultDateRange = {
  startDate: null,
  endDate: null,
  key: "selection",
};

const formatDateTime = (date) => {
  return date ? new Date(date).toISOString() : null;
};

export default function Inbox() {
  // States and constants
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem("lastActiveTab");
    return savedTab !== null ? savedTab : "savedDrafts";
  });
  const [selectedRange, setSelectedRange] =
    useState(defaultDateRange);
  const activeTabObject = TAB_ARRAY.find(
    (tab) => tab.id === activeTab,
  );
  const [queryOptions, setQueryOptions] = useState({
    filter: activeTabObject?.serverFilterKey,
    limit: 15,
    offset: 0,
    sortAscending: false,
    startDate: null,
    endDate: null,
  });
  const [notesData, setNotesData] = useState(null);
  const [notesLoading, setNotesLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  // Hooks
  const { resetSelectedNotesList } = useNoteMultiselectContext();
  const { pollInterval } = useFlags();

  const { refetch: notesRefetch, fetchMore } = useQuery(
    GET_INBOX_NOTES,
    {
      variables: queryOptions,
      pollInterval: pollInterval ?? 10000,
      onError: () => {
        setNotesLoading(false);
      },
    },
  );

  // Handlers
  const handleApplyDateRange = (startDate, endDate) => {
    const formattedStartDate = startDate
      ? formatDateTime(startOfDay(startDate))
      : null;
    const formattedEndDate = endDate
      ? formatDateTime(endOfDay(endDate))
      : null;
    setSelectedRange({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    setQueryOptions((prevOptions) => ({
      ...prevOptions,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    fetchNotes({
      ...queryOptions,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    tabCountRefetch({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const { data, refetch: tabCountRefetch } = useQuery(
    TAB_COUNT_QUERY,
    {
      variables: {
        startDate: queryOptions.startDate,
        endDate: queryOptions.endDate,
      },
      pollInterval: pollInterval ?? 10000,
    },
  );

  // Data fetching functions
  const fetchNotes = async (options) => {
    const { data } = await notesRefetch(options);
    setNotesData(data);
    setNotesLoading(false);
  };

  const handleFetchMore = async () => {
    const { data: newData } = await fetchMore({
      variables: {
        ...queryOptions,
        offset: notesData.inboxNotes.notes.length,
      },
    });
    setNotesData((prevData) => ({
      inboxNotes: {
        ...prevData.inboxNotes,
        notes: [
          ...prevData.inboxNotes.notes,
          ...newData.inboxNotes.notes,
        ],
        totalNotes: newData.inboxNotes.totalNotes,
      },
    }));
    setNotesLoading(false);
  };

  // Effects
  useEffect(() => {
    localStorage.setItem("lastActiveTab", activeTab); // Update local storage when the activeTab changes, record the last active tab
  }, [activeTab]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    // show loading only on tab change and first load
    // otherwise, other note actions will show loading
    // and the UI will feel jumpy
    setNotesLoading(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setQueryOptions((prevOptions) => {
        const updatedOptions = {
          ...prevOptions,
          filter: activeTabObject?.serverFilterKey,
        };
        notesRefetch(updatedOptions);
        tabCountRefetch({
          startDate: updatedOptions.startDate,
          endDate: updatedOptions.endDate,
        });
        return updatedOptions;
      });
      await fetchNotes({
        ...queryOptions,
        filter: activeTabObject?.serverFilterKey,
      });
      setShouldRefetch(false);
    };

    fetchData();
  }, [activeTab, shouldRefetch]);

  useWatchNoteUpdates({
    refetch: () => {
      setShouldRefetch(true);
    },
  });

  // Rendering functions
  const renderDateRangeSelection = () => {
    return (
      <DateRangeSelection
        selectedRange={selectedRange}
        handleApplyDateRange={handleApplyDateRange}
      />
    );
  };

  return (
    <>
      <JoyrideTour
        steps={inboxStepsBasic}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />

      <PageTitle
        title="Inbox"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
        renderAdditionalHeaderComponent={renderDateRangeSelection}
      />

      <div className="mt-6">
        <Tabs
          id="inboxTabs"
          tabArray={TAB_ARRAY}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabCounts={data?.inboxTabCount}
          handleTabClick={(tabId) => {
            if (tabId !== activeTab) {
              mixpanel.track("Switched Inbox Tab", {
                oldTab: activeTab,
                newTab: tabId,
              });
              resetSelectedNotesList();
              setNotesLoading(true);
              setActiveTab(tabId);
            }
          }}
        >
          {TAB_ARRAY.map((tab) => {
            if (tab.id !== activeTab) {
              return null;
            }

            return (
              <div key={tab.id} id={tab.id}>
                <InboxWindow
                  tabId={tab.id}
                  tabTitle={tab.title}
                  colorStyles={tab.colorStyles}
                  description={[tab.description]}
                  activeTab={activeTab}
                  isExportWindow={tab?.isExportWindow}
                  isAlreadyExported={!!tab?.isAlreadyExported}
                  data={notesData}
                  refetch={() => {
                    setShouldRefetch(true);
                  }}
                  fetchMore={handleFetchMore}
                  notesLoading={notesLoading}
                />
              </div>
            );
          })}
        </Tabs>
      </div>
      <MultiSelectActionContainer
        notes={notesData?.inboxNotes?.notes}
        refetch={notesRefetch}
      />
    </>
  );
}
