const getAppDownloadLink = () => {
  const userAgent = navigator.userAgent;

  const isIOS =
    /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(userAgent);
  const isNewerIPad =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;

  let downloadUrl = "";

  if (isIOS) {
    downloadUrl =
      "https://apps.apple.com/app/scribenote/id6738748386?utm_source=sn-web-app&utm_medium=link&utm_campaign=web-app";
  } else if (isNewerIPad) {
    downloadUrl =
      "https://apps.apple.com/app/scribenote/id6738748386?utm_source=sn-web-app&utm_medium=link&utm_campaign=web-app";
  } else if (isAndroid) {
    downloadUrl =
      "https://play.google.com/store/apps/details?id=com.scribenote.rebarked.prod&referrer=utm_source%3Dsn-web-app%26utm_medium%3Dlink%26utm_campaign%3Dweb-app";
  } else {
    downloadUrl =
      "https://docs.scribenote.com/en/articles/8588492-how-to-install-the-scribenote-mobile-app";
  }

  return downloadUrl;
};

export default getAppDownloadLink;
