import { useLDClient } from "launchdarkly-react-client-sdk";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";
import AppIconImg from "../../assets/imgs/icon-512.png";
import {
  SuperSoapProvider,
  NoteMultiselectProvider,
  UploadProgressProvider,
  useAuth,
  useWindowDimensions,
  useAuthExpiry,
  OnboardingProvider,
  usePreventRecordingInterruptions,
  AreYouSureModal,
  useDarkMode,
} from "../../hooks";
import { RoleProvider } from "../../hooks/use-role";
import AnalyticsRouter from "../../utils/analytics/AnalyticsRouter";
import UserAccount from "../account/UserAccount.js";
import AdminPage from "../admin";
import TeamManagement from "../admin/team";
import PatientAnalytics from "../analytics/PatientAnalytics.js";
import Attachments from "../attachments/Attachments.js";
import {
  AuditTrailNote,
  AuditTrailPatient,
  AuditTrailUser,
} from "../audit";
import FirstLoginTour from "../auth/FirstLogin/FirstLoginTour";
import RegisterConfirm from "../auth/FirstLogin/RegisterConfirm.js";
import ForgotPassword from "../auth/ForgotPassword.js";
import ForgotPasswordReset from "../auth/ForgotPasswordReset";
import Login from "../auth/Login.js";
import Register from "../auth/Register.js";
import getAppDownloadLink from "../common/AppDownloadLink";
import { PermanentlyHideableBanner } from "../common/Banner";
import { MessageModal } from "../common/modals/PopupModals";
import UserDashboard from "../dashboard/UserDashboard.js";
import SharedExportNote from "../export/SharedExportNote.js";
import FourOhFour from "../home/FourohFour.js";
import Inbox from "../inbox/Inbox";
import CopyPatientSelect from "../notebook/CopyPatientSelect";
import KeyPatientInfo from "../notebook/KeyPatientInfo.js";
import MovePatientSelect from "../notebook/MovePatientSelect";
import Notebook from "../notebook/Notebook.js";
import CreateNote from "../notes/CreateNote";
import CreateNoteSelect from "../notes/CreateNoteSelect";
import EditNote from "../notes/EditNote";
import Patients from "../patients/Patients.js";
import Quicknotes from "../quicknotes/Quicknotes.js";
import EditTemplate from "../templates/EditTemplate.js";
import NewTemplate from "../templates/NewTemplate.js";
import Templates from "../templates/Templates";

function PromptUserRefreshAuthExpiry({
  shouldShowModal,
  setShouldShowModal,
}) {
  return (
    <div>
      <MessageModal
        shouldShow={shouldShowModal}
        setShouldShow={setShouldShowModal}
        dismissible={true}
        title=""
      >
        <div className="flex flex-col self-center mb-4">
          <h4 className="text-lg text-center mb-2">
            Your session has expired.
          </h4>
          <h5 className="text-sm text-center">
            You have been logged out for security purposes. Please
            refresh the page to be taken to the login page!
          </h5>
        </div>
      </MessageModal>
    </div>
  );
}

export function PrivateRoute({ children }) {
  const auth = useAuth();
  const { shouldShowModal, setShouldShowModal } = useAuthExpiry();
  const ldClient = useLDClient();

  useEffect(() => {
    const userUuid = localStorage.getItem("root_logged_in_user_uuid");

    if (userUuid) {
      ldClient.identify({
        key: `user-uuid-${userUuid}`,
      });
    }
  }, [ldClient]);

  function renderPrivateRouteOrRedirect() {
    if (auth.isUserAuthenticated) {
      return children;
    } else {
      return <Navigate to={"/auth/login"} />;
    }
  }

  return (
    <>
      {renderPrivateRouteOrRedirect()}
      <PromptUserRefreshAuthExpiry
        shouldShowModal={shouldShowModal}
        setShouldShowModal={setShouldShowModal}
      />
    </>
  );
}

function AdminProtectedRoute({ children }) {
  const { adminPage } = useFlags();
  const { orgUuid } = useAuth();

  return orgUuid && adminPage ? children : <Navigate to={"/"} />;
}

function App(props) {
  const mainClassNameOriginal =
    "flex flex-col flex-grow w-11/12 md:w-[85%] self-center justify-start";
  const { width } = useWindowDimensions();
  const location = useLocation();
  useDarkMode();
  const {
    shouldShowAreYouSureModal,
    hideAreYouSureModal,
    confirmAction,
  } = usePreventRecordingInterruptions();

  return (
    <div
      id="root-node"
      className="flex flex-col min-h-screen font-body dark:bg-gray-800"
    >
      {location.pathname === "/onboarding" ? null : <Header />}
      <main className={mainClassNameOriginal}>
        {width < 500 && (
          <PermanentlyHideableBanner>
            <div className="flex flex-row items-center space-x-4">
              <a
                href={getAppDownloadLink()}
                target="_blank"
                rel="noreferrer"
              >
                <div className="w-16">
                  <img
                    src={AppIconImg}
                    className="cover rounded-xl w-full"
                  />
                </div>
              </a>
              <span className="text-left">
                We&#39;ve detected you&#39;re using Scribenote on a
                mobile device. <br /> For a more optimized mobile
                experience, tap the icon to download our mobile app.
              </span>
            </div>
          </PermanentlyHideableBanner>
        )}
        <AreYouSureModal
          shouldShowAreYouSureModal={shouldShowAreYouSureModal}
          hideAreYouSureModal={hideAreYouSureModal}
          confirmAction={confirmAction}
        />
        <AnalyticsRouter>
          <Routes>
            {/* PUBLIC & AUTH ROUTES */}
            <Route path="/" element={<Login />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/register" element={<Register />} />
            <Route
              path="/forgot_password"
              element={<ForgotPassword />}
            />
            <Route
              path="/reset_password/:resetToken"
              element={<ForgotPasswordReset />}
            />
            <Route
              path="/registration_confirmation"
              element={
                <PrivateRoute>
                  <RegisterConfirm />
                </PrivateRoute>
              }
            />

            {/* END AUTH ROUTES */}

            <Route
              path="/account"
              element={
                <PrivateRoute>
                  <UploadProgressProvider>
                    <UserAccount />
                  </UploadProgressProvider>
                </PrivateRoute>
              }
            />

            {/* CLINICAL ROUTES */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <OnboardingProvider>
                    <UserDashboard />
                  </OnboardingProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <PrivateRoute>
                  <OnboardingProvider>
                    <RoleProvider>
                      <FirstLoginTour />
                    </RoleProvider>
                  </OnboardingProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <NoteMultiselectProvider>
                    <Inbox />
                  </NoteMultiselectProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/templates"
              element={
                <PrivateRoute>
                  <Templates />
                </PrivateRoute>
              }
            />
            <Route
              path="/templates/new"
              element={
                <PrivateRoute>
                  <SuperSoapProvider>
                    <NewTemplate />
                  </SuperSoapProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/templates/edit/:uuid"
              element={
                <PrivateRoute>
                  <SuperSoapProvider>
                    <EditTemplate />
                  </SuperSoapProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook"
              element={
                <PrivateRoute>
                  <Patients />
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook/:patientUUID"
              element={
                <PrivateRoute>
                  <NoteMultiselectProvider>
                    <Notebook />
                  </NoteMultiselectProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook/:patientUUID/keyinfo"
              element={
                <PrivateRoute>
                  <KeyPatientInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook/:patientUUID/attachments"
              element={
                <PrivateRoute>
                  <UploadProgressProvider>
                    <Attachments />
                  </UploadProgressProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook/analytics"
              element={
                <PrivateRoute>
                  <PatientAnalytics />
                </PrivateRoute>
              }
            />
            <Route
              path="/quicknotes"
              element={
                <PrivateRoute>
                  <NoteMultiselectProvider>
                    <Quicknotes />
                  </NoteMultiselectProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook_select/move/:noteUUIDs"
              element={
                <PrivateRoute>
                  <MovePatientSelect />
                </PrivateRoute>
              }
            />
            <Route
              path="/notebook_select/copy/:noteUUIDs"
              element={
                <PrivateRoute>
                  <CopyPatientSelect />
                </PrivateRoute>
              }
            />
            <Route
              path="/assistant_export_note/:noteAccessToken"
              element={<SharedExportNote />}
            />
            <Route
              path="/shared_export_note/:noteAccessToken"
              element={<SharedExportNote />}
            />
            <Route
              path="/audit_trail/note/:noteUUID"
              element={
                <PrivateRoute>
                  <AuditTrailNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit_trail/patient/:patientUUID"
              element={
                <PrivateRoute>
                  <AuditTrailPatient />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit_trail/user"
              element={
                <PrivateRoute>
                  <AuditTrailUser />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit_note/:noteUUID"
              element={
                <PrivateRoute>
                  <SuperSoapProvider>
                    <EditNote />
                  </SuperSoapProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/new_note/select"
              element={
                <PrivateRoute>
                  <CreateNoteSelect />
                </PrivateRoute>
              }
            />
            <Route
              path="/new_note/patient/:patientUUID"
              element={
                <PrivateRoute>
                  <CreateNote />
                </PrivateRoute>
              }
            />
            <Route
              path="/new_note/quicknote"
              element={
                <PrivateRoute>
                  <CreateNote />
                </PrivateRoute>
              }
            />
            {/* <Route
            path="/super_soap"
            element={
              <PrivateRoute>
                <SuperSoapProvider>
                  <SuperSoap />
                </SuperSoapProvider>
              </PrivateRoute>
            }
          /> */}
            <Route
              path="/admin"
              element={
                <AdminProtectedRoute>
                  <PrivateRoute>
                    <AdminPage />
                  </PrivateRoute>
                </AdminProtectedRoute>
              }
            />
            <Route
              path="/admin/:teamUuid"
              element={
                <AdminProtectedRoute>
                  <PrivateRoute>
                    <TeamManagement />
                  </PrivateRoute>
                </AdminProtectedRoute>
              }
            />
            {/* All routes must be above this 404 or they will redirect. */}
            <Route path="*" element={<FourOhFour />} />
          </Routes>
        </AnalyticsRouter>

        {props.children}
      </main>
      {location.pathname === "/onboarding" ? null : <Footer />}
    </div>
  );
}

export default App;
