import { useMutation, gql, useQuery } from "@apollo/client";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import EDIT_USER from "../../../graphql/mutations/EditUser.js";
import currentUserQuery from "../../../graphql/queries/CurrentUser.js";
import { useAccount } from "../../../hooks";
import Twemoji from "../../../Twemoji.js";
import { alert } from "../../common/Alert.js";
import EditDeleteButtons from "../../common/buttons/EditDeleteButtons.js";
import { ErrorMessage, Input } from "../../common/inputs/Input.js";
import { Button } from "../form/Button.js";

const CONFIRM_USER_MUTATION = gql`
  mutation confirmUser($confirmationCode: String) {
    confirmUser(confirmationCode: $confirmationCode) {
      ok
    }
  }
`;

export const RESEND_CONFIRMATION_EMAIL_MUTATION = gql`
  mutation resendConfirmUser {
    resendConfirmUser {
      ok
    }
  }
`;

export default function RegisterConfirm() {
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const {
    control,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [confirmUser] = useMutation(CONFIRM_USER_MUTATION);
  const [resendConfirmUser] = useMutation(
    RESEND_CONFIRMATION_EMAIL_MUTATION,
  );
  const [editUser] = useMutation(EDIT_USER);

  const { data, refetch } = useQuery(currentUserQuery, {
    fetchPolicy: "no-cache",
  });

  const account = useAccount();

  function submitConfirmation({ confirmationCode }) {
    confirmUser({
      variables: {
        confirmationCode,
      },
    })
      .then((response) => {
        const { ok } = response.data.confirmUser;

        account.updateAccountContext({
          isConfirmed: true,
          isUserOnFreeTrial: true,
          accountType: "basic",
        });
        if (ok) {
          navigate("/account");
        }
      })
      .catch((response) => {
        console.log(response);
        const errors = response.graphQLErrors.map((error) =>
          alert("error", error.message),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  }

  if (!data) {
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit(submitConfirmation)}
      className="mt-16 flex flex-col items-center space-y-10"
    >
      <div className="flex flex-col md:flex-row items-center md:space-x-4">
        <span className="text-4xl transform animate-[handwave_1s_ease-in-out_infinite]">
          <Twemoji emoji="👋" />
        </span>{" "}
        <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-gray-300">
          Hey {data?.user?.firstName ?? ""}! Please confirm your
          email.
        </h2>
      </div>

      <div className="flex flex-col w-full max-w-[1200px] lg:items-stretch lg:flex-row lg:items-start items-center justify-center space-y-10 lg:space-x-12 lg:space-y-0 pt-10">
        <div className="flex w-3/4 lg:w-1/2 flex-1 flex-col items-center space-y-8">
          <div className="flex flex-row items-center space-x-2">
            <div className="h-8 w-8 bg-indigo-500 text-white font-bold rounded-full flex items-center justify-center">
              1
            </div>
            <h4>Send confirmation email</h4>
          </div>

          <div className="w-full justify-center flex-1 flex flex-col space-y-4 p-10 rounded-xl shadow-md shadow-indigo-100 dark:shadow-gray-900 bg-white dark:bg-gray-800">
            <div className="flex flex-row items-center justify-center space-x-2">
              {isEditingEmail ? (
                <div className="flex flex-col items-center w-full">
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        disabled={!isEditingEmail}
                        onChange={(event) => {
                          onChange(event.target.value);
                          if (!isEditingEmail) {
                            if (event.target.value.length > 0) {
                              isEditingEmail(true);
                            }
                          }
                        }}
                        defaultValue={data?.user?.email}
                        type="email"
                      />
                    )}
                    name="email"
                    rules={{
                      required: isEditingEmail,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message:
                          "Entered value does not match email format",
                      },
                    }}
                  />
                  {errors.email && (
                    <ErrorMessage message="Please enter a valid email address!" />
                  )}
                </div>
              ) : (
                <h4 className="truncate">{data?.user?.email}</h4>
              )}

              {!isEditingEmail ? (
                <button
                  onClick={() => {
                    setIsEditingEmail(true);
                  }}
                  className="h-7 w-7 shrink-0 rounded-full bg-indigo-500 hover:bg-indigo-600 text-sm text-white font-medium transition-colors"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              ) : (
                <EditDeleteButtons
                  onClickDestructiveButton={() => {
                    resetField("email");
                    setIsEditingEmail(false);
                  }}
                  destructiveButtonText="Cancel"
                  onClickActionButton={async () => {
                    const { email } = getValues();

                    if (email == data?.user?.email || !email) {
                      setIsEditingEmail(false);
                      return;
                    }

                    editUser({ variables: { email } })
                      .then(() => {
                        alert("success", `Email changed to ${email}`);
                        refetch();
                      })
                      .catch((response) => {
                        response.graphQLErrors.map((error) =>
                          alert("error", error.message),
                        );
                      });

                    setIsEditingEmail(false);
                  }}
                  actionButtonText="Save"
                />
              )}
            </div>
            <div className="flex-1 md:flex-none">
              <Button
                type="button"
                onClick={async () => {
                  await resendConfirmUser();
                  alert(
                    "success",
                    `A new confirmation code has been sent to ${data?.user?.email}`,
                  );
                }}
              >
                Send Email
              </Button>
            </div>
          </div>
        </div>
        <div className="flex w-3/4 lg:w-1/2 flex-1 flex-col items-center space-y-8">
          <div className="flex flex-row items-center space-x-2">
            <div className="h-8 w-8 bg-indigo-500 text-white font-bold rounded-full flex items-center justify-center">
              2
            </div>
            <h4>Enter confirmation code</h4>
          </div>

          <div className="w-full justify-center flex-1 flex flex-col space-y-4 p-10 rounded-xl shadow-md shadow-indigo-100 dark:shadow-gray-900 bg-white dark:bg-gray-800">
            <div className="flex flex-row items-center justify-center space-x-2">
              <Controller
                control={control}
                name="confirmationCode"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    placeholder={"Type or paste your code here"}
                    {...field}
                  />
                )}
              />
              {errors.confirmationCode && (
                <ErrorMessage message="Please enter your confirmation code." />
              )}
            </div>
            <div className="flex-1 md:flex-none">
              <Button type="submit">Confirm</Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
