import { useMutation } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import mixpanel from "mixpanel-browser";
import React, { useState, useEffect } from "react";

import { AccountItemLine } from "./AccountItem.js";
import UPDATE_USER_FF_SEGMENT from "../../graphql/mutations/UpdateUserFfSegment";
import { toTitleCase } from "../../utils/index.js";
import { alert } from "../common/Alert.js";
import ToggleSwitch from "../common/buttons/ToggleSwitch";

const FeatureToggle = ({
  title,
  description,
  flagKey,
  segmentKey,
  invertToggle = false,
}) => {
  const { [flagKey]: initialState } = useFlags();
  const [isEnabled, setIsEnabled] = useState(initialState);

  const [updateUserFfSegment, { loading }] = useMutation(
    UPDATE_USER_FF_SEGMENT,
  );

  useEffect(() => {
    setIsEnabled(initialState);
  }, [initialState]);

  const handleToggle = async () => {
    if (loading) return;
    try {
      const action = invertToggle
        ? isEnabled
          ? "ADD"
          : "REMOVE"
        : isEnabled
        ? "REMOVE"
        : "ADD";

      const response = await updateUserFfSegment({
        variables: {
          action,
          segmentKey,
        },
      });

      if (!response.data.updateUserFfSegment.success) {
        alert(
          "error",
          "Failed to update setting. Please try again later or contact support.",
        );
        return;
      } else {
        alert("success", "Setting updated successfully.");
        mixpanel.track("Updated Account Setting", {
          settingName: toTitleCase(segmentKey),
          settingKey: segmentKey,
          settingValue: action,
        });
      }

      setIsEnabled(!isEnabled);
    } catch (error) {
      alert(
        "error",
        "An error has occurred. Please try again later or contact support.",
      );
    }
  };

  return (
    <AccountItemLine>
      <div className="dark:text-gray-300 text-xs md:text-sm lg:w-96 mr-4">
        <strong>{title}: </strong>
        {description}
      </div>
      <div className="flex flex-row items-center space-x-2 mx-2 cursor-pointer">
        <ToggleSwitch
          toggleState={invertToggle ? !isEnabled : isEnabled}
          setToggleState={handleToggle}
          disabled={loading}
        />
      </div>
    </AccountItemLine>
  );
};

export default FeatureToggle;
