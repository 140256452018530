import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router";

import ExportNoteItem from "./ExportNoteItem";
import { sharedExportSteps } from "../../assets/data/JoyrideTourSteps.js";
import { AttachmentsPreview } from "../attachments/AttachmentsPreview";
import HelpButton from "../common/buttons/HelpButton";
import Loading from "../common/loading/Loading.js";
import JoyrideTour from "../common/modals/JoyrideTour.js";

const GET_NOTE_FOR_SHARED_EXPORT = gql`
  query shared_access_note($noteAccessToken: String) {
    sharedAccessNote(noteAccessToken: $noteAccessToken) {
      uuid
      createdAt
      isTagged
      temperature
      heartRate
      respiratoryRate
      weight
      capillaryRefillTime
      bodyConditionScore
      cardiovascular
      coatAndSkin
      ears
      eyes
      gastrointestinal
      lymphatic
      musculoskeletal
      neurological
      oral
      reproductiveAndUrinary
      respiratory
      overall
      subjective
      objective
      assessment
      plan
      additionalNotes
      shouldUseGenericObjective
      shouldUseCustomForm
      jobType
      customObjectiveFields {
        uuid
        name
        value
        symmetricalValueLeft
        symmetricalValueRight
        symmetryType
        symmetryLabels {
          left
          right
        }
      }
      customFormFields {
        uuid
        textValue
      }
      patient {
        uuid
        name
        lastNameOfOwner
        sex
        species
        breed
      }
      user {
        uuid
        firstName
        lastName
        account {
          uuid
          logoFileLink
          headerInfoPdf
        }
      }
      attachments {
        uuid
        fileName
        fileLink
        createdAt
      }
    }
  }
`;

export default function SharedExportNote() {
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  let { noteAccessToken } = useParams();

  const { data, loading, error } = useQuery(
    GET_NOTE_FOR_SHARED_EXPORT,
    {
      variables: { noteAccessToken },
    },
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="mb-10">
      <JoyrideTour
        steps={sharedExportSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />

      <div className="flex flex-row items-center space-x-4">
        <h2 className="text-left text-gray-700 dark:text-gray-300">
          A note has been shared with you
        </h2>
        <div className="flex-1 h-0.5 rounded-full bg-gradient-to-r from-indigo-500 to-indigo-700"></div>
        <HelpButton
          onClick={() => {
            setRunJoyrideTour(true);
          }}
        />
      </div>

      {error ? (
        <div className="flex flex-col py-4">
          <h4>Your access to this note has expired.</h4>
          <h5>
            For security reasons, we only enable shared access to
            notes for a limited time. If you still need access to this
            note, please reach out to the Scribenote user who sent it
            to you.
          </h5>
          <br />
          <h5>
            Next time you recieve a Scribenote note via email, please
            take action as soon as possible to ensure you can access
            the note.
          </h5>
          <h5>
            If you need help, please contact{" "}
            <a
              className=" text-indigo-500 hover:text-indigo-300"
              href="mailto:support@scribenote.com"
            >
              support@scribenote.com
            </a>
          </h5>
        </div>
      ) : (
        <div className="mt-4">
          {data?.sharedAccessNote ? (
            <div>
              <ExportNoteItem
                key={1}
                isForSharedExport={true}
                exportNoteData={data?.sharedAccessNote}
              />
              <div className="mt-2">
                {data?.sharedAccessNote?.attachments?.length ? (
                  <AttachmentsPreview
                    attachments={data?.sharedAccessNote?.attachments}
                    isCompactView={false}
                    attachmentPreviewText="Files attached to this note"
                    emptyStateText="No files are currently attached to this note"
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <p>
              An error has occurred, please contact
              support@scribenote.com
            </p>
          )}
        </div>
      )}
    </div>
  );
}
