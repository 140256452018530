import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAccount } from "./use-account.js";
import { alert } from "../components/common/Alert.js";

export const useRespondToStripeCheckoutComplete = () => {
  const { updateAccountContext } = useAccount();
  const navigate = useNavigate();
  const [isCheckoutComplete, setIsCheckoutComplete] = useState(false);
  // Hacky way to update the account context and navigate to the dashboard
  // after the user has successfully subscribed
  // Scoped here only because this is rendered everywhere relevant
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (
      params.get("checkoutComplete") === "true" &&
      !isCheckoutComplete
    ) {
      setIsCheckoutComplete(true);
      updateAccountContext({
        accountType: "unlimited",
        isSubscribed: true,
        isUserOnFreeTrial: false,
      });
      mixpanel.track("Upgrade Plan Success");

      navigate("/account");
      alert("success", "Upgrade Successful, you have great taste 🤩");
    }
  }, [window.location.search]);
};
