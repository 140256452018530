import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AccountItem, AccountItemLine } from "./AccountItem";
import { UpgradePlanButton } from "./UpgradePlanButton";
import { NoteUsageCounter } from "../layout/header/NoteUsageCounter";

export default function FreePlanSettings({ user }) {
  return (
    <AccountItem
      title="Subscription Details"
      icon={<FontAwesomeIcon icon={faSync} size="lg" color="gray" />}
    >
      <div className="flex-1 divide-y dark:divide-gray-700">
        <AccountItemLine title="Plan Type:">
          You are currently on the Free Plan.
        </AccountItemLine>
        <AccountItemLine title="Usage:">
          <div className="flex flex-col md:flex-row items-center text-center md:text-left md:space-x-2 space-y-2 md:space-y-0">
            <NoteUsageCounter small />

            <span className="text-xs md:text-sm">
              Upgrade your account for unlimited Auto-Notes.
            </span>
          </div>
        </AccountItemLine>
      </div>

      {user?.email?.includes("@mvetpartners.com") ||
      user?.email?.includes("@svp.vet") ? null : (
        <div
          id="accountSubscriptionOptions"
          className="flex flex-row md:flex-col md:items-end items-center justify-center md:space-y-2"
        >
          <UpgradePlanButton />
        </div>
      )}
    </AccountItem>
  );
}
