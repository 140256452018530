import { useFlags } from "launchdarkly-react-client-sdk";
import { debounce } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";

const DEBOUNCE_SAVE_DELAY_MS = 6000;

const autosaveContext = createContext();

export function useAutosaveContext() {
  return useContext(autosaveContext);
}

function useProvideAutosave() {
  const [isSaved, setIsSaved] = useState(true);
  const [isFormErrors, setIsFormErrors] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const debouncedSaveRef = useRef(null);

  const cancelDebounce = useCallback(() => {
    if (debouncedSaveRef.current) {
      debouncedSaveRef.current.cancel();
    }
    setIsSaved(true);
  }, [debouncedSaveRef]);

  return {
    isSaved,
    setIsSaved,
    isFormErrors,
    setIsFormErrors,
    isStreaming,
    setIsStreaming,
    cancelDebounce,
    debouncedSaveRef,
  };
}

export function AutosaveProvider({ children }) {
  const autosave = useProvideAutosave();

  return (
    <autosaveContext.Provider value={autosave}>
      {children}
    </autosaveContext.Provider>
  );
}

export function useAutosave({ save, getValues, watch, control }) {
  const {
    setIsSaved,
    setIsFormErrors,
    isStreaming,
    setIsStreaming,
    debouncedSaveRef,
  } = useAutosaveContext();

  const location = useLocation();
  const isScribe = location?.pathname?.includes("scribetool");
  const { autosaveUnmount } = useFlags();
  const objectiveFields = useWatch({
    control,
    name: "defaultObjectiveFieldArray",
  });

  const shouldUseGenericObjective = useWatch({
    control,
    name: "shouldUseGenericObjective",
  });

  const customObjectiveFields = useWatch({
    control,
    name: "customObjectiveFieldArray",
  });

  const colorScheme = useWatch({
    control,
    name: "colorScheme",
  });
  const clientSummary = useWatch({
    control,
    name: "clientSummary",
  });

  const debouncedSave = useCallback(
    debounce(async () => {
      const data = getValues();
      const result = await save({
        data,
        isDraft: true,
        isAutosave: true,
      });
      if (result === "form-errors") {
        setIsFormErrors(true);
      } else {
        setIsSaved(true);
        setIsFormErrors(false);
      }
    }, DEBOUNCE_SAVE_DELAY_MS),
    [],
  );

  useEffect(() => {
    debouncedSaveRef.current = debouncedSave;
    const subscription = watch(() => {
      if (!isScribe && !isStreaming) {
        setIsSaved(false);
        debouncedSave();
      }
    });
    return () => {
      subscription.unsubscribe();
      if (autosaveUnmount) {
        debouncedSave.cancel();
      }
    };
  }, [
    watch,
    objectiveFields,
    customObjectiveFields,
    colorScheme,
    shouldUseGenericObjective,
    clientSummary,
    isStreaming,
    debouncedSave,
    setIsSaved,
    setIsStreaming,
  ]);
}
