import { useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { DashboardInbox } from "./dashboardInbox/DashboardInbox.js";
import { DashboardRecorder } from "./DashboardRecorder.js";
import OrgValueBanner from "./OrgValueBanner.js";
import { TestRecordings } from "./TestRecordings.js";
import ValueBanner from "./ValueBanner.js";
import { dashboardSteps } from "../../assets/data/JoyrideTourSteps.js";
import currentUserQuery from "../../graphql/queries/CurrentUser.js";
import { useAuth, useOnboarding } from "../../hooks";
import FullScreenLoading from "../common/loading/FullScreenLoading.js";
import JoyrideTour from "../common/modals/JoyrideTour.js";
import PageTitle from "../layout/PageTitle.js";

function UserDashboard() {
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [
    shouldPreventBackNavigation,
    setShouldPreventBackNavigation,
  ] = useState(false);
  const [
    shouldRefetchForNewRecording,
    setShouldRefetchForNewRecording,
  ] = useState(false);

  useOnboarding();

  const { loading, data } = useQuery(currentUserQuery, {
    fetchPolicy: "no-cache",
  });

  const { adminPage } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("popstate", handler);
    };
  }, []);

  useEffect(() => {
    const shouldPrevent = location?.state?.shouldPreventBackNavigation
      ? location.state.shouldPreventBackNavigation
      : false;

    setShouldPreventBackNavigation(shouldPrevent);
  }, [location]);

  const handler = () => {
    if (shouldPreventBackNavigation) {
      console.log(
        "We've prevented the use of the back button here since it will take you back to the subscriptions page, a potentially state changing action. If you would like to change your subscription properly, please visit your account page.",
      );
      navigate("/dashboard", {
        shouldPreventBackNavigation: true,
      });
    }
  };

  window.addEventListener("popstate", handler);

  if (loading && !data?.user) {
    return null;
  }

  return (
    <div className="lg:max-h-screen">
      <FullScreenLoading loadingTrigger={loading} />

      <JoyrideTour
        steps={dashboardSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />
      <TestRecordings data={data} />

      <div className="relative">
        <PageTitle
          title="Dashboard"
          id="dashboardJoyride"
          onClick={() => {
            setRunJoyrideTour(true);
          }}
          showHelpButton
        />
      </div>

      {auth?.orgUuid && adminPage ? (
        <OrgValueBanner />
      ) : (
        <ValueBanner user={data?.user} />
      )}
      <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10 my-6">
        <div className="flex-1 mt-20 md:mt-0 self-center justify-self-center flex items-center justify-center text-gray-600 dark:text-gray-400">
          <DashboardRecorder
            shouldRefetchForNewRecording={
              shouldRefetchForNewRecording
            }
            setShouldRefetchForNewRecording={
              setShouldRefetchForNewRecording
            }
          />
        </div>

        <div id="dashboardInbox" className="flex-1 md:max-w-lg">
          <DashboardInbox
            shouldRefetchForNewRecording={
              shouldRefetchForNewRecording
            }
            setShouldRefetchForNewRecording={
              setShouldRefetchForNewRecording
            }
          />
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
