import mixpanel from "mixpanel-browser";
import React from "react";

import { useLocalStorage } from "../../hooks";
import { toTitleCase } from "../../utils";
import ToggleSwitch from "../common/buttons/ToggleSwitch";

function PreferenceToggle({
  toggleState,
  setToggleState,
  preferenceKey,
}) {
  return (
    <div className="flex flex-row items-center space-x-2 cursor-pointer">
      <ToggleSwitch
        toggleState={toggleState}
        setToggleState={setToggleState}
        toggleCallback={() => {
          mixpanel.track("Updated Account Setting", {
            settingName: toTitleCase(preferenceKey),
            settingKey: preferenceKey,
            settingValue: !toggleState,
          });
        }}
      />
    </div>
  );
}

export function ExportSOAPKeyInfoToggle() {
  const preferenceKey = "should-export-note-key-info";
  const [shouldShowNoteKeyInfo, setShouldShowNoteKeyInfo] =
    useLocalStorage(preferenceKey);

  return (
    <PreferenceToggle
      preferenceKey={preferenceKey}
      toggleState={shouldShowNoteKeyInfo}
      setToggleState={setShouldShowNoteKeyInfo}
    />
  );
}

export function ExportSOAPSignalmentToggle() {
  const preferenceKey = "should-show-patient-signalment";
  const [
    shouldShowPatientSignalment,
    setShouldShowPatientSignalment,
  ] = useLocalStorage(preferenceKey);

  return (
    <PreferenceToggle
      preferenceKey={preferenceKey}
      toggleState={shouldShowPatientSignalment}
      setToggleState={setShouldShowPatientSignalment}
    />
  );
}

export function PageSaverToggle() {
  const preferenceKey = "page-saver";
  const [shouldEnablePageSaver, setShouldEnablePageSaver] =
    useLocalStorage(preferenceKey);

  return (
    <PreferenceToggle
      preferenceKey={preferenceKey}
      toggleState={shouldEnablePageSaver}
      setToggleState={setShouldEnablePageSaver}
    />
  );
}
