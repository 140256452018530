import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { faFlag } from "@fortawesome/free-regular-svg-icons";
import {
  faCommentDots,
  faFileMedical,
  faHistory,
  faPhone,
  faTooth,
  faFileWaveform,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextareaAutosize } from "@mui/base";
import { Tooltip } from "@mui/material";
import { useState, useRef } from "react";
import { createPortal } from "react-dom";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FLAG_NOTE_TO_SUPPORT from "../../../../graphql/mutations/FlagNoteToSupport";
import { alert } from "../../../common/Alert";
import { BasicButton } from "../../../common/buttons/BasicButton";
import { MessageModal } from "../../../common/modals/PopupModals";
import { RETRY_FAILED_AUTO_SOAP } from "../../../notebook/note/NoteStatus";

const GET_USER = gql`
  {
    user {
      uuid
      email
      firstName
      lastName
    }
  }
`;

const recordTypes = {
  medical_record: "Medical Record",
  dental_record: "Dental Record",
  callback: "Callback",
  recap: "Recap",
  dictation: "Dictation",
  abdominal_ultrasound: "Abdominal Ultrasound",
  multipet: "Multipet",
  packmode: "Pack Mode",
};

function AutoNoteTypeSelectionButtons({
  selectedOption,
  setSelectedOption,
  retryFailedAutoSoap,
  note,
  navigate,
}) {
  const handleOptionSelect = (option) => {
    setSelectedOption(option);

    retryFailedAutoSoap({
      variables: {
        noteUuid: note?.uuid,
        jobType: option,
      },
    })
      .then(() => {
        let optionLabel = recordTypes[option] || "Medical Record";
        alert("warning", `Retrying note as ${optionLabel}...`);

        if (location.pathname != "/dashboard") {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        alert("error", error.message);
      });
  };

  const unselectedClassName =
    "p-2 rounded-lg bg-indigo-200 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:text-white font-medium text-sm w-full shadow-sm hover:bg-indigo-300";
  const selectedClassName =
    unselectedClassName + " ring-2 ring-indigo-600 text-indigo-600";

  return (
    <div className="flex flex-col items-start space-y-2">
      <Tooltip
        arrow
        placement="left"
        title="This will format your recording into a SOAP note."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("medical_record")}
            className={
              selectedOption === "medical_record"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faFileMedical} className="mr-2" />
            Medical Record (SOAP)
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will format your recording into a SOAP note."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("multipet")}
            className={
              selectedOption === "multipet"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faFileMedical} className="mr-2" />
            Multipet (SOAP)
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will format your recording into a SOAP note."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("packmode")}
            className={
              selectedOption === "packmode"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faFileMedical} className="mr-2" />
            Pack Mode (SOAP)
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will format your recording into a Dental Record, with a dental chart."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("dental_record")}
            className={
              selectedOption === "dental_record"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faTooth} className="mr-2" />
            Dental Record
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will summarize your recording."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("recap")}
            className={
              selectedOption === "recap"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faHistory} className="mr-2" />
            Recap
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will summarize your client callback recording."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("callback")}
            className={
              selectedOption === "callback"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            Callback
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will generate a word-for-word transcript of your recording."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("abdominal_ultrasound")}
            className={
              selectedOption === "abdominal_ultrasound"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faFileWaveform} className="mr-2" />
            Abdominal Ultrasound
          </button>
        </span>
      </Tooltip>
      <Tooltip
        arrow
        placement="left"
        title="This will generate a word-for-word transcript of your recording."
      >
        <span className="w-full">
          <button
            onClick={() => handleOptionSelect("dictation")}
            className={
              selectedOption === "dictation"
                ? selectedClassName
                : unselectedClassName
            }
          >
            <FontAwesomeIcon icon={faCommentDots} className="mr-2" />
            Dictation
          </button>
        </span>
      </Tooltip>
    </div>
  );
}

export default function SomethingWrongButton({ note }) {
  const [shouldShowFlagNoteModal, setShouldShowFlagNoteModal] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { control, getValues, reset } = useForm({
    defaultValues: {
      flagComment: "",
    },
  });

  const [retryFailedAutoSoap] = useMutation(RETRY_FAILED_AUTO_SOAP);
  const [flagNoteToSupport] = useMutation(FLAG_NOTE_TO_SUPPORT);

  const navigate = useNavigate();

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  let submitting = useRef(false);

  function handleSubmit() {
    if (submitting.current) {
      return false;
    }
    submitting.current = true;
    const data = getValues();
    getUser().then((response) => {
      flagNoteToSupport({
        variables: {
          email: response?.data?.user?.email,
          customerName: `${response?.data?.user?.firstName} ${response?.data?.user?.lastName}`,
          comment: data?.flagComment,
          noteUuid: note?.uuid,
          title: note?.title,
        },
      })
        .then(() => {
          alert(
            "success",
            "Sent info to our support team. We'll reach out when it's fixed!",
          );
          reset();
          setShouldShowFlagNoteModal(false);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          submitting.current = false;
        });
    });
  }

  return (
    <>
      {createPortal(
        <MessageModal
          shouldShow={shouldShowFlagNoteModal}
          setShouldShow={(state) => {
            setShouldShowFlagNoteModal(state);
            reset();
          }}
          dismissible
          title="Something Wrong?"
          width="max-w-2xl"
        >
          <div className="flex flex-row items-start divide-x dark:divide-gray-600/50 p-2">
            <div className="flex-1 pr-6 space-y-2">
              <h4>Reprocess Note</h4>
              <h5 className="text-xs md:text-sm text-gray-500">
                This recording was processed as{" "}
                <span className="font-medium text-indigo-500">
                  a {note?.jobTypeName || note?.jobType}
                </span>
                . Would you like it to be processed as another note
                type?
              </h5>
              <AutoNoteTypeSelectionButtons
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                note={note}
                retryFailedAutoSoap={retryFailedAutoSoap}
                navigate={navigate}
              />
            </div>
            <div className="flex flex-col flex-1 pl-6 space-y-2">
              <h4>Send to Support</h4>
              <h5 className="text-xs md:text-sm text-gray-500">
                Let us know what&#39;s wrong, and we&#39;ll look into
                it. More details are preferred!
              </h5>
              <Controller
                render={({ field }) => (
                  <TextareaAutosize
                    minRows={5}
                    placeholder={"The note is blank!"}
                    className="resize-none appearance-none relative block w-full px-2 py-1 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm h-8"
                    {...field}
                  />
                )}
                control={control}
                name="flagComment"
              />
              <div className="self-end">
                <BasicButton onClick={() => handleSubmit()}>
                  Send →
                </BasicButton>
              </div>
            </div>
          </div>
        </MessageModal>,
        document.getElementById("root-node"),
      )}

      <button
        className={`text-gray-400 dark:text-gray-500 dark:hover:text-gray-700 hover:bg-red-400 hover:text-white px-2 py-1 rounded-md text-xs md:text-sm transition-all`}
        onClick={() =>
          setShouldShowFlagNoteModal(!shouldShowFlagNoteModal)
        }
      >
        <FontAwesomeIcon icon={faFlag} /> Something Wrong?
      </button>
    </>
  );
}
