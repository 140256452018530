import mixpanel from "mixpanel-browser";

import { handleSubscribe } from "../../../api/Billing";
import { UNLIMITED_PRICE_ID_MONTHLY } from "../../../constants";
import { useNoteUsage } from "../../../hooks";
import { ConfirmModal } from "../../common/modals/PopupModals";

export default function NoteUsageWarningModal({
  warningLevel,
  setWarningLevel,
  startRecording,
}) {
  if (!warningLevel) {
    return null;
  }
  const { numAppointmentsUsed, numAppointmentsAllowed } =
    useNoteUsage();
  const dismissModal = () => setWarningLevel(null);
  const shouldShow = ["warning", "limit"].includes(warningLevel);
  if (!shouldShow) {
    return null;
  }
  return (
    <ConfirmModal
      shouldShow={shouldShow}
      hideModal={dismissModal}
      title={"Upgrade Account"}
      cancelText={"I'll Upgrade Later"}
      confirmText={"Upgrade"}
      cancelAction={() => {
        dismissModal();
        startRecording(true);
      }}
      confirmAction={() => {
        mixpanel.track("Upgrade Clicked from modal");
        handleSubscribe({
          priceId: UNLIMITED_PRICE_ID_MONTHLY,
        });
        dismissModal();
      }}
    >
      {warningLevel == "limit" ? (
        <h5>
          You've reached your Auto-Note limit. Please upgrade your
          account to continue creating Medical Record SOAPs. By
          clicking "I'll Upgrade Later", you'll still be able to
          record your appointment, but it won't be converted into a
          Medical Record SOAP.
        </h5>
      ) : (
        <h5>
          You've almost reached your Auto-Note limit (
          {numAppointmentsUsed}/{numAppointmentsAllowed}). You'll need
          to upgrade your account soon to continue creating automatic
          Medical Record SOAPs.
        </h5>
      )}
    </ConfirmModal>
  );
}
