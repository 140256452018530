import { MenuItem, Select } from "@mui/material";
import mixpanel from "mixpanel-browser";
import React from "react";

import { ChangeStepButton } from "./FirstLoginTour";

export default function ReferralSourceStep({
  setReferralSource,
  referralSource,
  goToPreviousStep,
  goToNextStep,
  darkModeEnabled,
}) {
  const options = [
    {
      label: "Social Media (Instagram, Facebook, etc.)",
      value: "social",
    },
    { label: "VIN or Reddit", value: "vin_reddit" },
    { label: "From a Friend", value: "word_of_mouth" },
    { label: "Google Search", value: "google_search" },
    { label: "Webinar", value: "webinar" },
    { label: "Conference", value: "conference" },
    { label: "Podcast", value: "podcast" },
    { label: "Advertisement", value: "advertisement" },
    { label: "Other", value: "other" },
  ];

  return (
    <>
      <div className="flex flex-1 flex-col items-center justify-between text-center space-y-10 max-h-[50vh]">
        <h3 className="text-center">
          How did you hear about Scribenote?
        </h3>

        <div className="text-left">
          <Select
            size="small"
            sx={[
              {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
              {
                fontSize: "14px",
                color: darkModeEnabled ? "#f9fafb" : "black",
                backgroundColor: darkModeEnabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                borderRadius: "8px",
                flex: 1,
                width: 300,
              },
            ]}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: darkModeEnabled
                    ? "rgb(55 65 81)"
                    : "rgb(243 244 246)",
                },
              },
            }}
            value={referralSource}
            onChange={(e) => setReferralSource(e.target.value)}
            displayEmpty
          >
            <MenuItem
              value=""
              disabled
              sx={{
                fontSize: "14px",
                color: darkModeEnabled ? "#f9fafb" : "black",
              }}
            >
              Select an option
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  fontSize: "14px",
                  color: darkModeEnabled ? "#f9fafb" : "black",
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="mt-10 flex flex-col md:flex-row justify-center md:gap-4">
          <ChangeStepButton
            onClick={goToPreviousStep}
            text={"← Back"}
          />
          <ChangeStepButton
            text={"Next →"}
            onClick={() => {
              mixpanel.track("Completed Referral Source Step", {
                referralSource,
              });
              goToNextStep();
            }}
            disabled={!referralSource}
            disabledText={"Next →"}
            withSpinner={false}
          />
        </div>
      </div>
    </>
  );
}
