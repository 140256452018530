import mixpanel from "mixpanel-browser";
import React from "react";
import { useNavigate } from "react-router-dom";

import QRCodes from "./AppStoreQRCodes";
import { ChangeStepButton } from "./FirstLoginTour";
import { useOnboarding } from "../../../hooks";

export default function FinalStep({
  userRole,
  clinicGroup,
  referralSource,
  goToPreviousStep,
}) {
  const { setShouldShowFirstLoginTour, userCompleteOnboarding } =
    useOnboarding();

  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-2 p-4">
        <h3 className="text-center">
          One more thing.. download the Scribenote app!
        </h3>
        <p className="text-center">
          The app is the most convenient way to start recording your
          appointments.
        </p>
      </div>
      <QRCodes />
      <div className="flex flex-col md:w-[600px] items-center text-center space-y-4">
        <p>
          Scan one of the QR codes above (or tap/click) to download
          the Scribenote app from the Apple App Store or the Google
          Play Store.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row justify-center md:gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton
          onClick={() => {
            userCompleteOnboarding(
              userRole,
              clinicGroup,
              referralSource,
            );
            setShouldShowFirstLoginTour(false);
            navigate("/dashboard");
            mixpanel.track("Finished Final Onboarding Step", {
              userRole,
              clinicGroup,
              referralSource,
            });
          }}
          text={"I'm done!"}
        />
      </div>
    </>
  );
}
