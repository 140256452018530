import { useQuery, gql } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import AnalyticsSettings from "./AnalyticsSettings";
import { AutoSoapSettingsAccount } from "./AutoSOAPSettings";
import EmailExportingSettings from "./EmailExportingSettings";
import FreePlanSettings from "./FreePlanSettings";
import PDFCustomizationSettings from "./PDFCustomizationSettings";
import PreferencesSettings from "./PreferencesSettings";
import ProfileSettings from "./ProfileSettings";
import ReferralSettings from "./ReferralSettings";
import RevisionsSettings from "./RevisionsSettings";
import SubscriptionSettings from "./SubscriptionSettings";
import TeamSettings from "./Teams/TeamSettings";
import { accountSteps } from "../../assets/data/JoyrideTourSteps";
import {
  useAuth,
  useAccount,
  useRespondToStripeCheckoutComplete,
} from "../../hooks";
import Twemoji from "../../Twemoji";
import { getTempScribenoteFlask } from "../../utils";
import { alert } from "../common/Alert";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import JoyrideTour from "../common/modals/JoyrideTour";
import { ConfirmModal } from "../common/modals/PopupModals.js";
import PageTitle from "../layout/PageTitle";

const GET_USER_ACCOUNT_DETAILS = gql`
  {
    rootUser {
      uuid
      firstName
      lastName
      teams {
        uuid
        name
        users {
          uuid
          firstName
          lastName
          email
        }
      }
    }
    user {
      uuid
      email
      firstName
      lastName
      isConfirmed
      createdAt
      autoExportEmail
      emailExpiryDays
      shouldEmbedEmailExports
      shouldUseAutoSoaps
      isAppointmentsBetaUser
      numReferralsMade
      referral {
        referralCode
      }
      account {
        uuid
        logoFileLink
        shouldHidePresetTemplates
        headerInfoPdf
        accountType
        isSubscribed
        isTeamsCustomer
        isStudent
        freeTrialExpiresAt
        accountDetails {
          cardBrand
          cardNumber
          cardExpiryDate
          cardholderName
          planName
          nextBillingDate
          billingFrequency
          planPrice
          nextInvoiceInfo
          cardholderAddress
          isCanceled
          isCanceledFinal
          isOnFreeTrial
        }
        autoUsageDetails {
          isActiveAutoUser
          monthlyUsageCount
          monthlyTotalPrice
        }
      }
    }
  }
`;

export default function UserAccount() {
  const [
    shouldShowCancelSubscriptionModal,
    setShouldShowCancelSubscriptionModal,
  ] = useState(false);
  const [
    shouldShowDeleteAccountModal,
    setShouldShowDeleteAccountModal,
  ] = useState(false);
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);

  const { loading, data, refetch } = useQuery(
    GET_USER_ACCOUNT_DETAILS,
  );

  const auth = useAuth();
  const account = useAccount();
  const { control, setValue } = useForm();

  useRespondToStripeCheckoutComplete();

  if (!loading && !data) {
    return null;
  }

  const { user, rootUser } = data || {};

  const SCRIBENOTE_FLASK = getTempScribenoteFlask();

  function hideModal() {
    setShouldShowCancelSubscriptionModal(false);
    setShouldShowDeleteAccountModal(false);
  }

  return (
    <>
      <FullScreenLoading loadingTrigger={loading} />
      <PageTitle
        showHelpButton
        title="Account"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
      />
      <JoyrideTour
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
        steps={accountSteps}
      />
      <ConfirmModal
        shouldShow={shouldShowDeleteAccountModal}
        hideModal={hideModal}
        title={"Delete Account"}
        cancelText={"Never Mind"}
        confirmText={"Yes, Delete"}
        cancelAction={() => hideModal()}
        confirmAction={async () => {
          account.removeLocalStorageAccount();
          await auth.deleteAccount();
        }}
      >
        <h5>
          Are you sure you&apos;d like to{" "}
          <strong className="text-red-500">
            delete your account
          </strong>
          ? We&apos;d be paw-fully sad to see you go{" "}
          <Twemoji emoji="😿" />
        </h5>
      </ConfirmModal>
      <ConfirmModal
        shouldShow={shouldShowCancelSubscriptionModal}
        hideModal={hideModal}
        title={"Cancel Subscription"}
        cancelText={"Never Mind"}
        confirmText={"Yes, Cancel"}
        cancelAction={() => hideModal()}
        confirmAction={async () => {
          await fetch(`${SCRIBENOTE_FLASK}/cancel-subscription`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: {},
            credentials: "include",
          });

          mixpanel.track("Subscription Canceled");
          alert("success", "Subscription canceled successfully.");

          setTimeout(function () {
            window.location.replace(
              "https://scribenote.typeform.com/to/vSrMU2ZC",
            );
          }, 3500);
        }}
      >
        <h5 className="text-sm font-bold">
          This will have the following effects:
        </h5>
        <div className="flex flex-col items-center text-gray-700 dark:text-gray-300">
          <ul className="list-disc text-sm">
            <li>
              - Your plan will remain active until the end of the
              billing cycle.
            </li>
            <li>- You will be downgraded to the free plan.</li>
            <li>
              - You will no longer be able to create unlimited Medical
              Records.
            </li>
            <li>- All of your existing notes will be maintained.</li>
          </ul>
        </div>

        <h4 className="py-4">
          Are you sure you&apos;d like to{" "}
          <strong>cancel your subscription</strong>? We&apos;d be
          paw-fully sad to see you go <Twemoji emoji="😿" />
        </h4>
      </ConfirmModal>
      <div
        id="accountOverview"
        className="mt-10 grid gap-6 grid-cols-1"
      >
        <ProfileSettings user={user} />

        <TeamSettings rootUser={rootUser} />

        {/* don't allow students in this whole block, needs refactor */}
        {user?.account?.isSubscribed &&
        !user?.account?.accountDetails?.isCanceledFinal ? (
          <SubscriptionSettings
            user={user}
            setShouldShowCancelSubscriptionModal={
              setShouldShowCancelSubscriptionModal
            }
          />
        ) : (
          <FreePlanSettings user={user} />
        )}

        {user?.isAppointmentsBetaUser ? (
          <ReferralSettings user={user} />
        ) : null}

        <AutoSoapSettingsAccount
          accountType={user?.account?.accountType}
          shouldUse={user?.shouldUseAutoSoaps}
        />

        <EmailExportingSettings
          control={control}
          setValue={setValue}
          data={data}
          refetch={refetch}
        />

        <PDFCustomizationSettings
          data={data}
          refetch={refetch}
          loading={loading}
        />
        <PreferencesSettings data={data} refetch={refetch} />

        <RevisionsSettings />

        <AnalyticsSettings />
      </div>

      {user?.email?.includes("@mvetpartners.com") ||
      user?.email?.includes("@svp.vet") ? null : (
        <button
          id="accountDelete"
          onClick={() => setShouldShowDeleteAccountModal(true)}
          className="text-sm font-medium text-red-400 mt-5 float-right transition-all hover:text-red-700 focus:outline-none"
        >
          Delete Account
        </button>
      )}
    </>
  );
}
