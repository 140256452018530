import mixpanel from "mixpanel-browser";
import React from "react";

import AppStoreLink from "../../../assets/imgs/qr-code-app-store.png";
import GooglePlayLink from "../../../assets/imgs/qr-code-play-store.png";

export default function QRCodes() {
  return (
    <div className="flex flex-row items-center space-x-4 md:space-x-24 md:py-6">
      <a
        className="flex flex-col items-center"
        href="https://apps.apple.com/ca/app/scribenote/id6738748386?utm_source=sn-web-app&utm_medium=link&utm_campaign=web-app"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          mixpanel.track("Clicked IOS Download Link");
        }}
      >
        <img
          src={AppStoreLink}
          className="w-[140px] mb-2 rounded-xl"
        />
        <h5>For iPhone</h5>
      </a>
      <a
        className="flex flex-col items-center"
        href="https://play.google.com/store/apps/details?id=com.scribenote.rebarked.prod&referrer=utm_source%3Dsn-web-app%26utm_medium%3Dlink%26utm_campaign%3Dweb-app"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          mixpanel.track("Clicked Google Play Download Link");
        }}
      >
        <img
          src={GooglePlayLink}
          className="w-[140px] mb-2 rounded-xl"
        />
        <h5>For Android</h5>
      </a>
    </div>
  );
}
