import mixpanel from "mixpanel-browser";
import React from "react";

import { ChangeStepButton } from "./FirstLoginTour";
import AdminThumbnail from "../../../assets/imgs/thumbnail-admin.png";
import ManagerThumbnail from "../../../assets/imgs/thumbnail-manager.png";
import TechThumbnail from "../../../assets/imgs/thumbnail-tech.png";
import VetThumbnail from "../../../assets/imgs/thumbnail-vet.png";
import { useRole } from "../../../hooks/use-role";

function OptionCard({ img, title, value, isSelected, onSelect }) {
  return (
    <div
      onClick={() => onSelect(value)}
      className={`h-auto flex flex-col items-center justify-center p-6 w-56 border dark:border-gray-900 rounded-xl cursor-pointer space-y-3 hover:bg-indigo-200 dark:hover:bg-indigo-800 transition-colors ${
        isSelected
          ? "dark:bg-indigo-800 bg-indigo-200"
          : "dark:bg-gray-700 bg-transparent"
      }`}
    >
      {img && (
        <img
          src={img}
          width={100}
          height={100}
          className="object-cover rounded-2xl overflow-clip"
        />
      )}
      <h4>{title}</h4>
    </div>
  );
}

export default function UserTypeSelectionStep({
  goToNextStep,
  goToPreviousStep,
  userType,
  setUserType,
}) {
  const { allowedRoles } = useRole();
  const userOptions = [
    {
      title: "Veterinarian",
      img: VetThumbnail,
      value: allowedRoles.DVM,
    },
    {
      title: "Vet Tech",
      img: TechThumbnail,
      value: allowedRoles.TECHNICIAN,
    },
    {
      title: "Practice Manager",
      img: ManagerThumbnail,
      value: allowedRoles.PRACTICE_MANAGER,
    },
    {
      title: "Clinic Admin/Other",
      img: AdminThumbnail,
      value: allowedRoles.OTHER,
    },
  ];

  return (
    <>
      <h3 className="flex flex-row text-center items-center">
        Which option best represents your role?
      </h3>

      <div className="flex flex-col md:flex-row items-stretch text-center md:space-x-6 space-y-4 md:space-y-0 max-w-2xl h-full">
        {userOptions.map((option) => (
          <OptionCard
            key={option.value}
            title={option.title}
            img={option.img}
            value={option.value}
            isSelected={userType === option.value}
            onSelect={(value) => {
              setUserType(value);
            }}
          />
        ))}
      </div>

      <div className="pt-6 flex flex-col md:flex-row justify-center md:gap-4">
        <ChangeStepButton
          onClick={goToPreviousStep}
          text={"← Back"}
        />
        <ChangeStepButton
          disabled={!userType}
          disabledText={"Next →"}
          withSpinner={false}
          onClick={() => {
            mixpanel.track("Completed User Type Selection Step", {
              userType,
            });
            goToNextStep();
          }}
          text={"Next →"}
        />
      </div>
    </>
  );
}
