import mixpanel from "mixpanel-browser";

import { handleSubscribe } from "../../api/Billing";
import { UNLIMITED_PRICE_ID_MONTHLY } from "../../constants";
import { BasicButton } from "../common/buttons/BasicButton";

export const UpgradePlanButton = () => {
  return (
    <BasicButton
      onClick={() => {
        mixpanel.track("Upgrade Plan Clicked");

        handleSubscribe({
          priceId: UNLIMITED_PRICE_ID_MONTHLY,
        });
      }}
    >
      Upgrade Plan
    </BasicButton>
  );
};
