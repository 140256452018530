import React from "react";
import { createPortal } from "react-dom";
import { FormProvider, useWatch } from "react-hook-form";

import ExportForm from "./ExportForm";
import { ConfirmModal } from "../common/modals/PopupModals";

export default function ExportModal({
  shouldShowModal,
  hideModal,
  handleSubmit,
  onSend,
  control,
  methods,
  getValues,
  isClientSummaryMode = false,
  isClientSummaryModalOpen = false,
  frontmatter,
  backmatter,
  note,
  shouldOnlyShowEmailForm = null,
}) {
  const clientSummary = useWatch({ control, name: "clientSummary" });

  return createPortal(
    <ConfirmModal
      shouldShow={shouldShowModal}
      hideModal={hideModal}
      title={`Export Your ${
        isClientSummaryMode ? "Client Summary" : "Note"
      }`}
      confirmText={"Send"}
      cancelText={"Cancel"}
      cancelAction={hideModal}
      confirmAction={handleSubmit(onSend)}
    >
      <FormProvider {...methods}>
        <ExportForm
          clientSummary={clientSummary}
          isClientSummaryModalOpen={isClientSummaryModalOpen}
          frontmatter={frontmatter}
          backmatter={backmatter}
          isClientSummaryMode={isClientSummaryMode}
          control={control}
          methods={methods}
          getValues={getValues}
          note={note}
          shouldOnlyShowEmailForm={shouldOnlyShowEmailForm}
          isInboxModalMode={!isClientSummaryMode}
        />
      </FormProvider>
    </ConfirmModal>,
    document.getElementById("root-node"),
  );
}
