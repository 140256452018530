import mixpanel from "mixpanel-browser";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const AnalyticsRouter = ({ children }) => {
  const location = useLocation();
  const lastPathRef = useRef(null);
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    const currentPath = location.pathname;
    const currentTime = Date.now();

    // Skip logging for automatic redirects (e.g., from "/" to "/dashboard")
    if (lastPathRef.current === "/" && currentPath === "/dashboard") {
      lastPathRef.current = currentPath;
      startTimeRef.current = currentTime;
      return;
    }

    // Calculate time spent on the previous page; default to 0 for direct entries from search or refresh
    const timeSpent = lastPathRef.current
      ? (currentTime - startTimeRef.current) / 1000
      : 0;

    mixpanel.track("Page Navigation", {
      from: lastPathRef.current || "Direct Entry or Refresh",
      to: currentPath,
      duration: timeSpent,
    });

    // Update refs for the next page tracking
    lastPathRef.current = currentPath;
    startTimeRef.current = currentTime;

    // Handle tab close (and also refresh)
    const handleBeforeUnload = () => {
      const unloadTimeSpent =
        (Date.now() - startTimeRef.current) / 1000;
      mixpanel.track("Page Navigation", {
        from: lastPathRef.current,
        to: "Tab Closed, Refreshed, or Navigated to an External Link",
        duration: unloadTimeSpent,
      });
    };

    // If you use <a> tags for internal navigation this will be called, so please use react router for internal navigation
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  return children;
};

export default AnalyticsRouter;
