import mixpanel from "mixpanel-browser";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";

import { uploadToCloud } from "../api/UploadAudio";
import { alert } from "../components/common/Alert";
import { db } from "../db";

const syncNotesContext = createContext();

export function useSyncNotes() {
  return useContext(syncNotesContext);
}

function useProvideSyncNotes() {
  const [syncNotesQueue, setSyncNotesQueue] = useState([]);
  const [uploadIndex, setUploadIndex] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({
    numChunksTotal: 0,
    numChunksCompleted: 0,
  });

  useEffect(() => {
    async function processQueue() {
      if (!syncNotesQueue.length || uploadIndex !== null) return;

      const currentItem = syncNotesQueue[0];
      setUploadIndex(currentItem.id);

      try {
        const syncedRecordingUUID = uuidv4();

        const response = await uploadToCloud({
          blob: currentItem.blob,
          createNewNote: currentItem.createNewNote,
          jobType: currentItem.jobType,
          syncedRecordingUUID,
          uploadStatus,
          setUploadStatus,
          uploadType: currentItem.uploadType,
        });

        if (!response?.success) throw new Error("Upload failed.");

        // Successfully uploaded, now delete the recording from the database
        await db.unsyncedRecordings.delete(currentItem.id);

        alert("success", "Recording synced successfully!");
      } catch (error) {
        alert("warning", "Sync failed. Please try again.");
        console.error(error);
        mixpanel.track("Recording Sync Failed", {
          recordingId: currentItem.id,
          error: error.message,
        });
      } finally {
        setSyncNotesQueue((prev) => prev.slice(1)); // Update the queue
        setUploadIndex(null);
      }
    }

    processQueue();
  }, [syncNotesQueue, uploadIndex]);

  const addToSyncNotesQueue = useCallback((item, createNewNote) => {
    setSyncNotesQueue((prev) => {
      if (prev.some((queuedItem) => queuedItem.id === item.id))
        return prev; // Prevent duplicates
      return [...prev, { ...item, createNewNote }];
    });
  }, []);

  return {
    syncNotesQueue,
    uploadIndex,
    uploadStatus,
    addToSyncNotesQueue,
  };
}

export function SyncNotesProvider({ children }) {
  const syncNotes = useProvideSyncNotes();

  return (
    <syncNotesContext.Provider value={syncNotes}>
      {children}
    </syncNotesContext.Provider>
  );
}
