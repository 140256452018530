import {
  faDownload,
  faSyncAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLiveQuery } from "dexie-react-hooks";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { createPortal } from "react-dom";

import Confetti from "../../../assets/imgs/Confetti.png";
import PartyScribbles from "../../../assets/imgs/party-scribbles.png";
import { db } from "../../../db";
import { useSyncNotes } from "../../../hooks";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import { BasicButton } from "../buttons/BasicButton";
import { ProgressBar } from "../inputs/AttachmentSelection";
import { MessageModal, ConfirmModal } from "../modals/PopupModals";

function EmptyState() {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center justify-center space-y-5">
        <div
          className="w-full min-h-[170px] flex-1 flex items-center justify-center"
          style={{
            backgroundImage: `url(${Confetti})`,
            backgroundRepeat: "repeat-x",
            backgroundSize: "contain",
          }}
        >
          <img
            className="flex self-center"
            width="100px"
            src={PartyScribbles}
          />
        </div>
        <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
          All recordings have been synced!
        </h4>
        <h5 className="max-w-md text-center text-sm text-gray-400 dark:text-gray-500">
          Yay! Your unsynced recordings have all been uploaded to our
          servers. You should now see them in your Inbox!
        </h5>
      </div>
    </div>
  );
}

function UnsyncedRecordingItem({
  item,
  blobURL,
  isSyncing,
  isQueued,
  percentCompleted,
  shouldShowSyncProgress,
  handleSync,
}) {
  const [
    shouldShowDeleteRecordingModal,
    setShouldShowDeleteRecordingModal,
  ] = useState(false);

  return (
    <>
      {createPortal(
        <ConfirmModal
          shouldShow={shouldShowDeleteRecordingModal}
          hideModal={() => setShouldShowDeleteRecordingModal(false)}
          title={"Delete this recording?"}
          confirmText="Yes, delete"
          cancelText="Cancel"
          confirmAction={() => {
            db.unsyncedRecordings.delete(item.id);
            alert("success", "Recording deleted.");
            setShouldShowDeleteRecordingModal(false);
          }}
          cancelAction={() =>
            setShouldShowDeleteRecordingModal(false)
          }
        >
          <h5>
            Are you sure you&apos;d like to delete this recording?
          </h5>
        </ConfirmModal>,
        document.body,
      )}

      <div className="p-3 bg-gray-50 border border-gray-100 dark:border-gray-600 dark:bg-gray-700 rounded-lg self-center flex flex-col transition-all space-y-3">
        <div className="flex-1 flex flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <NoteCreatedAt createdAt={item.saved_date} />
            <div
              className={`${
                item?.is_synced
                  ? "border-green-500 text-green-500 "
                  : "border-red-500 text-red-500"
              } text-xs border rounded-full px-2 py-1 items-center`}
            >
              {item?.is_synced ? "SYNCED" : "UNSYNCED"}
            </div>
          </div>
          <BasicButton
            onClick={() => handleSync(item)}
            disabled={isSyncing || isQueued}
          >
            <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
            {isSyncing
              ? "Syncing..."
              : isQueued
              ? "Queued for Sync"
              : "Sync"}
          </BasicButton>
        </div>

        <audio controls className="flex-1 rounded-lg">
          <source src={blobURL} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>

        <div className="flex-1 flex flex-row items-center space-x-2">
          <button
            className="flex items-center justify-center w-8 h-8 rounded-full bg-red-400 hover:bg-red-500"
            onClick={() => setShouldShowDeleteRecordingModal(true)}
          >
            <FontAwesomeIcon className="text-white" icon={faTimes} />
          </button>

          <div className="flex-1">
            <ProgressBar
              width="w-full"
              uploadProgress={
                shouldShowSyncProgress ? percentCompleted : 0
              }
            />
          </div>

          <button
            className="flex items-center justify-center w-8 h-8 rounded-full bg-indigo-500 hover:bg-indigo-600"
            onClick={() =>
              saveAs(
                item?.blob,
                `Scribenote-Recording-${moment(
                  item?.saved_date,
                ).format("MM-DD-YYYY-h-mm-a")}.wav`,
              )
            }
          >
            <FontAwesomeIcon
              icon={faDownload}
              size="xs"
              className="text-white"
            />
          </button>
        </div>
      </div>
    </>
  );
}

export default function UnsyncedRecordings({
  shouldShowUnsyncedRecordings,
  setShouldShowUnsyncedRecordings,
  createNewNote,
}) {
  const {
    addToSyncNotesQueue,
    uploadIndex,
    syncNotesQueue,
    uploadStatus,
  } = useSyncNotes();
  const percentCompleted = uploadStatus.numChunksTotal
    ? Math.round(
        (uploadStatus.numChunksCompleted * 100) /
          uploadStatus.numChunksTotal,
      )
    : 0;

  const allItems = useLiveQuery(
    () => db.unsyncedRecordings.toArray(),
    [],
  );

  const handleSync = (item) => {
    addToSyncNotesQueue(item, createNewNote);
  };

  const handleSyncAll = () => {
    allItems
      ?.filter(
        (item) =>
          !item.is_synced &&
          !syncNotesQueue.some(
            (queuedItem) => queuedItem.id === item.id,
          ),
      )
      .sort((a, b) => new Date(b.saved_date) - new Date(a.saved_date))
      .forEach((item) => {
        addToSyncNotesQueue(item, createNewNote);
      });
  };

  return createPortal(
    <MessageModal
      shouldShow={shouldShowUnsyncedRecordings}
      setShouldShow={setShouldShowUnsyncedRecordings}
      title={
        <div className="flex items-center justify-between">
          <span>Unsynced Recordings</span>
          {allItems?.length > 0 && (
            <BasicButton onClick={handleSyncAll}>
              <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
              Sync All
            </BasicButton>
          )}
        </div>
      }
      subtitle={
        allItems?.length > 0 ? (
          <span>
            Syncing recordings will upload them to our servers to be
            processed. <br />A stable internet connection is required
            to sync recordings.
          </span>
        ) : null
      }
      dismissible
    >
      <div className="pb-10 space-y-3">
        {allItems?.length > 0 ? (
          allItems
            .filter((item) => item?.blob)
            .sort(
              (a, b) =>
                new Date(b.saved_date) - new Date(a.saved_date),
            )
            .map((item) => {
              const blobURL = URL.createObjectURL(item.blob);
              const isSyncing = uploadIndex === item.id;
              const isQueued = syncNotesQueue.some(
                (queuedItem) => queuedItem.id === item.id,
              );

              return (
                <UnsyncedRecordingItem
                  key={item.id}
                  item={item}
                  blobURL={blobURL}
                  isSyncing={isSyncing}
                  isQueued={isQueued}
                  percentCompleted={percentCompleted}
                  shouldShowSyncProgress={isSyncing}
                  handleSync={handleSync}
                />
              );
            })
        ) : (
          <EmptyState />
        )}
      </div>
    </MessageModal>,
    document.getElementById("root-node"),
  );
}
